import { MediaMatcher } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, OnDestroy, ViewEncapsulation } from '@angular/core';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { DatosGeneralesFacadeService } from '../services/utils/datos-generales-facade.service';

@Component({
  selector: 'app-template',
  templateUrl: './template.component.html',
  styleUrls: ['./template.component.css'],
})
export class TemplateComponent implements OnDestroy {

  mobileQuery: MediaQueryList;
  opened:boolean = true;
  private _mobileQueryListener: () => void;
  public nombreEmpresa = "";
  public suscripcion:Subscription = new Subscription();

  constructor(
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    public datosGenerales:DatosGeneralesFacadeService
  ) {
    // this.elemento.ontoggle("toggled");
    this.mobileQuery = media.matchMedia('(min-width: 768px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);

    let datosEmpresa = JSON.parse(localStorage.getItem("infoEmpresa") || null);

    if(datosEmpresa === null && localStorage.getItem(`${environment.tokenLocal}`)){
      this.obtenerNombreEmpresa();
    }else{
      this.nombreEmpresa = datosEmpresa.nombreEmpresa;
    }

    this.opened = (this.mobileQuery.matches === true) ? true : false; 
  }
  
  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }
  ngAfterViewInit() {}

  obtenerNombreEmpresa(){
    this.datosGenerales.mostrarDatosGeneralesEmpresa('');

    this.suscripcion.add(
      this.datosGenerales.responseDatosGeneralesEmpresa$.subscribe((result)=>{
        localStorage.setItem("infoEmpresa", JSON.stringify(result));
        this.nombreEmpresa = result.nombreEmpresa;
      })
    );
  }
}
