import { NgModule } from '@angular/core';
import { Routes, RouterModule, ExtraOptions } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { CambioPasswordComponent } from './layout/cambio-password/cambio-password.component';
import { FullComponent } from './layout/full.component';
import { MosaicMenuComponent } from './layout/mosaic-menu/mosaic-menu.component';
import { PinSeguridadComponent } from './layout/pin-seguridad/pin-seguridad.component';
//import { LoginComponent } from './login/login/login.component';
import { ReportsModule } from './modules/reports/reports.module';
import { FacturaComponent } from './modules/sales/factura/factura.component';
import { FormatoCotizacionComponent } from './modules/sales/formato-cotizacion/formato-cotizacion.component';
import { InvoiceComponent } from './modules/sales/invoice/invoice.component';
import { PagesComponent } from './public/pages/pages.component';
// import { TransporteModule } from './modules/transporte/transporte.module';
import { RRHHModule } from './modules/rrhh/rrhh.module';
import { ProfileComponent } from './layout/profile/profile.component';
import { TemplateComponent } from './template/template.component';
import { AuthorizatedGuard } from './core/guards/authorizated.guard';
import { AuthTokenGuard } from './core/guards/auth-token.guard';
import { PageNotFoundComponent } from './layout/page-not-found/page-not-found.component';
import { environment } from 'src/environments/environment';
import { RecuperacionPasswordComponent } from './public/recuperacion-password/recuperacion-password.component';
import { LoginComponent } from './public/pages/expedientes/login/login.component';


const routerOptions: ExtraOptions = {
  anchorScrolling: "enabled",
  scrollPositionRestoration: 'enabled',
  scrollOffset: [0, 64],
}

const routes: Routes = [
  
  {
    path: '',
    canActivate: [AuthorizatedGuard],
    component: FullComponent,
    canActivateChild : [AuthTokenGuard],
    children: [
      {
        path: 'dashboard',
        loadChildren: () => import('./modules/dashboard/dashboard.module').then(m => m.DashboardModule)
      },
      {
        path: 'seguridad',
        loadChildren: () => import('./modules/seguridad/seguridad.module').then(m => m.SeguridadModule)
      },
      {
        path: 'ventas',
        loadChildren: () => import('./modules/sales/sales.module').then(m => m.SalesModule)
      },
      {
        path: 'transporte',
        loadChildren: () => import('./modules/transporte/transporte.module').then(m=>m.TransporteModule)
      },
      { path: 'shopping',
        loadChildren: () => import('./modules/shopping/shopping.module').then(m => m.ShoppingModule)
      },
      {
        path: 'config',
        loadChildren: () => import('./modules/configuracion/configuracion.module').then(m => m.ConfiguracionModule)
      },
      { path: 'reportes',
        loadChildren: () => import('./modules/reports/reports.module').then(m => m.ReportsModule)
      },
      { path: 'report',
        loadChildren: () => import('./modules/reportes/reportes.module').then(m => m.ReportesModule)
      },
      { path: 'cuentas por cobrar',
        loadChildren: () => import('./modules/cuentasporcobrar/cuentasporcobrar.module').then(m => m.CuentasporcobrarModule)
      },
      {
        path: 'accounting',
        // canActivate: [AuthorizatedGuard],
        loadChildren: () => import('./modules/contabilidad/contabilidad.module').then(m => m.ContabilidadModule)
      },
      {
        path: 'affiliates',
        loadChildren: () => import('./modules/afiliados/afiliados.module').then(m => m.AfiliadosModule)
      },
      {
        path: 'empleados',
        loadChildren: () => import('./modules/rrhh/rrhh.module').then(m => m.RRHHModule)
      },
      {
        path: 'usuario',
        loadChildren: () => import('./modules/user/user.module').then(m => m.UserModule)
      },
      {
        path: 'inventory',
        loadChildren: () => import('./modules/inventory/inventory.module').then(m => m.InventoryModule)
      },
      {
        path: 'credits',
        loadChildren: () => import('./modules/credits/credits.module').then(m => m.CreditsModule)
      }

    ]
  },
  {
    path: 'public-pages',
    component: PagesComponent,
    children: [
      {
        path: 'files',
        loadChildren: () => import('./public/pages/expedientes/expedientes.module').then(m => m.ExpedientesModule)
      }
    ]
  },
  {
    path: 'template',
    component: TemplateComponent,
    canActivateChild : [AuthTokenGuard],
    children: [
      {
        path: 'afiliados',
        loadChildren: () => import('./modules/clientes-afiliados/clientes-afiliados.module').then(m => m.ClientesAfiliadosModule)
      }
    ]
  },
  { path: 'print',
    outlet: 'print',
    component: FormatoCotizacionComponent,
    children: [
      { path: 'invoice', component: InvoiceComponent }
    ]
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'mosaicMenu',
    component: MosaicMenuComponent
  },
  {
    path: 'cambioPassword/:idTipoAccion',
    component: CambioPasswordComponent
  },
  {
    path: 'pinSeguridad/:idTipoAccion',
    component: PinSeguridadComponent
  },
  {
    path: 'perfil',
    component: ProfileComponent
  },
  {
    path: 'pageNotFound',
    component: PageNotFoundComponent
  },
  {
    path: 'recuperacionPassword',
    component: RecuperacionPasswordComponent
  },
  {
    path: '',
    redirectTo: environment.rutaDefecto,
    pathMatch: 'full',
  },
  {
    path: '**',
    redirectTo: 'pageNotFound',
    pathMatch: 'full',
  },
  
  
  /*,
  {
    path: 'ventas/puntos de venta',
    component: FacturaComponent
  },
*/
];

@NgModule({
  imports: [RouterModule.forRoot(routes, routerOptions)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
