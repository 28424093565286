
<div class="contenedorPadre">
    <mat-card class="container">
        <mat-card-content>
            <div class="forms-container">
                <div class="closeAlert">
                    <button mat-mini-fab class="buttonPrincipal" (click)="navegar()">
                        <mat-icon>exit_to_app</mat-icon>
                    </button>
                </div>
                <div class="img-signup">
                    <img src="assets/images/undraw_authentication_fsn5.svg" class="img-fluid">
                </div>
                <div class="signin-signup">

                    <form [formGroup]="claveForm" class="sign-in-form">
                        <h2 class="title">Cambio Contraseña</h2>
                        <div class="row">

                            <mat-form-field appearance="legacy" class="col-md-12">
                                <mat-label>Nueva Contraseña</mat-label>
                                <input matInput [type]="hide2 ? 'password' : 'text'" placeholder="Nueva Contraseña" formControlName="passNuevo" autocomplete="false"
                                    required>
                                <button type="button" mat-icon-button matSuffix (click)="hide2 = !hide2" [attr.aria-label]="'Hide password'"
                                    [attr.aria-pressed]="hide2">
                                    <mat-icon>{{hide2 ? 'visibility_off' : 'visibility'}}</mat-icon>
                                </button>
                            </mat-form-field>


                            <mat-form-field appearance="legacy" class="col-md-12">
                                <mat-label>Repetir Nueva Contraseña</mat-label>
                                <input matInput [type]="hide3 ? 'password' : 'text'" placeholder="Repetir Nueva Contraseña" formControlName="repetirPass" autocomplete="false"
                                    required>
                                <button type="button" mat-icon-button matSuffix (click)="hide3 = !hide3" [attr.aria-label]="'Hide password'" 
                                    [attr.aria-pressed]="hide3">
                                    <mat-icon>{{hide3 ? 'visibility_off' : 'visibility'}}</mat-icon>
                                </button>
                            </mat-form-field>
                        </div>
    
                        <div class="text-center">
                            <button mat-raised-button class="buttonPrincipal" (click)="cambiarPassword()"> Guardar</button>
                        </div>
    
                    </form>
    
                </div>
            </div>
    
        </mat-card-content>
    </mat-card>
</div>