import { Component, OnInit } from '@angular/core';
import { Router, RouterModule } from '@angular/router';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  public dataUsuario:any;

  constructor(public router:Router) {
    this.dataUsuario = JSON.parse(localStorage.getItem("data_go504"));
  }

  ngOnInit(): void {
  }

  volver(){
    let site = localStorage.getItem("site");
    if(site === "1"){
      this.router.navigateByUrl('/mosaicMenu');
    }else{
      this.router.navigateByUrl('/template/afiliados/mi-perfil');

    }
  }

  cambioPass(){
    this.router.navigateByUrl('cambioPassword/2');
  }

}
