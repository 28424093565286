import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, EMPTY, Observable, Subscription } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { DataApi } from 'src/app/Model/dataApi';
import { bancosInterface, cuentasBancosInterface, datosGeneralesInterface, FaceDetailsInterface, formasPagoInterface, tarjetasCreditoInterface } from 'src/app/Model/datosGeneralesInterface';
import { MensajesHttpService } from 'src/app/utils/mensajesHttp/mensajes-http.service';
import { environment } from 'src/environments/environment';
import { DataApiService } from '../data-api.service';
import { ToastrServiceLocal } from '../toast/toastr.service';

@Injectable({
  providedIn: 'root'
})
export class DatosGeneralesFacadeService {

  constructor(public _toast: ToastrServiceLocal, public _dataApi: DataApiService,
    public _mensajesHttp: MensajesHttpService, private router: Router) { };

  ngOnDestroy() {
    this.suscripciones.unsubscribe();
  }

  public suscripciones: Subscription = new Subscription();

  private Cargando$ = new BehaviorSubject<boolean>(false);
  public responseCargando$: Observable<boolean> = this.Cargando$.asObservable();

  private DatosGeneralesEmpresa$ = new BehaviorSubject<datosGeneralesInterface>({});
  public responseDatosGeneralesEmpresa$: Observable<datosGeneralesInterface> = this.DatosGeneralesEmpresa$.asObservable();

  private FormasPago$ = new BehaviorSubject<formasPagoInterface[]>([]);
  public responseFormasPago$: Observable<formasPagoInterface[]> = this.FormasPago$.asObservable();

  private TarjetasCredito$ = new BehaviorSubject<tarjetasCreditoInterface[]>([]);
  public responseTarjetasCredito$: Observable<tarjetasCreditoInterface[]> = this.TarjetasCredito$.asObservable();

  private Bancos$ = new BehaviorSubject<bancosInterface[]>([]);
  public responseBancos$: Observable<bancosInterface[]> = this.Bancos$.asObservable();

  private CuentasBanco$ = new BehaviorSubject<cuentasBancosInterface[]>([]);
  public responseCuentasBanco$: Observable<cuentasBancosInterface[]> = this.CuentasBanco$.asObservable();

  private FaceDetail$ = new BehaviorSubject<FaceDetailsInterface>({});
  public responseFaceDetail$: Observable<FaceDetailsInterface> = this.FaceDetail$.asObservable();


  
  mostrarDatosGeneralesEmpresa(params?: string) {
    this.Cargando$.next(true);

    const request$ = this._dataApi.GetDataApi(`${environment.site}/api/contabilidad/${environment.processEnv}/v1/reportes/infoGeneral/1`, params, 10).pipe(
      tap((result: DataApi | any) => {
        if (result.result.hasError === false) {
          this.DatosGeneralesEmpresa$.next(result.result.data.table0[0]);
        }
        this.Cargando$.next(false);
      }),
      catchError((error: HttpErrorResponse) => {
        this._mensajesHttp.mostrarErrorHttp(error, 'Ocurrio un error al mostrar los datos generales de la empresa', '');
        this.Cargando$.next(false);
        return EMPTY;
      })
    );
    return request$.subscribe();
  }

  mostrarFormasPago(params?: string) {
    this.Cargando$.next(true);

    const request$ = this._dataApi.GetDataApi(`${environment.site}/api/admin/${environment.processEnv}/v1/payment`, params, 1).pipe(
      tap((result: DataApi | any) => {
        if (result.hasError === false) {

          this.FormasPago$.next(result.data.table0);
        }
        this.Cargando$.next(false);
      }),
      catchError((error: HttpErrorResponse) => {
        this._mensajesHttp.mostrarErrorHttp(error, 'Ocurrio un error al mostrar las formas de pago', '');
        this.Cargando$.next(false);
        return EMPTY;
      })
    );
    return request$.subscribe();
  }

  mostrarTarjetasCredito(params?: string) {
    this.Cargando$.next(true);

    const request$ = this._dataApi.GetDataApi(`${environment.site}/api/admin/${environment.processEnv}/v1/creditCard`, params, 1).pipe(
      tap((result: DataApi | any) => {
        if (result.hasError === false) {

          this.TarjetasCredito$.next(result.data.table0);
        }
        this.Cargando$.next(false);
      }),
      catchError((error: HttpErrorResponse) => {
        this._mensajesHttp.mostrarErrorHttp(error, 'Ocurrio un error al mostrar las tarjetas de credito', '');
        this.Cargando$.next(false);
        return EMPTY;
      })
    );
    return request$.subscribe();
  }

  mostrarDatosGenerales(params?: string) {
    this.Cargando$.next(true);

    const request$ = this._dataApi.GetDataApi(`${environment.site}/api/sales/${environment.processEnv}/v1/ventas/infogeneral/1`, params, 2).pipe(
      tap((result: DataApi | any) => {
        if (result.result.hasError === false) {
          this.Bancos$.next(result.result.data.table0)
          this.CuentasBanco$.next(result.result.data.table5);
        }
        this.Cargando$.next(false);
      }),
      catchError((error: HttpErrorResponse) => {
        this._mensajesHttp.mostrarErrorHttp(error, 'Ocurrio un error al mostrar los datos generales para el pago', '');
        this.Cargando$.next(false);
        return EMPTY;
      })
    );
    return request$.subscribe();
  }

  validarCargaFoto(params: string, callback) {
    this.Cargando$.next(true);

    const request$ = this._dataApi.GetDataApi(`${environment.site}/api/utils/${environment.processEnv}/v1/rekognition/`, params, 4).pipe(
      tap((result: DataApi | any) => {

        if(result?.FaceDetails.length === 0){
          this._toast.mensajeWarning('','La imagen cargada no es valida');
        }
        if (result?.FaceDetails.length > 0) {
          if(result.FaceDetails[0].Confidence < environment.porcentajeValidezFotos){
            this._toast.mensajeWarning('','La imagen cargada no es valida');
          }else{
            callback(result.FaceDetails[0])
          }
        }
        this.Cargando$.next(false);
      }),
      catchError((error: HttpErrorResponse) => {
        this._mensajesHttp.mostrarErrorHttp(error, 'Ocurrio un error al validar la imagen cargada', '');
        this.Cargando$.next(false);
        return EMPTY;
      })
    );
    return request$.subscribe();
  }

  resetearFaceDetail(){
    this.FaceDetail$.next({});
  }

  

}
