import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { menuInterface } from 'src/app/Model/Seguridad/menu.Interface';

@Injectable({
  providedIn: 'root'
})
export class SidemeduFacadeService {

  // public menus = [{ texto: 'Inicio' }, { texto: 'Ventas' }, { texto: 'Inventario' },
  // {
  //   texto: 'Compras', hijos: [
  //     { texto: 'Solicitudes de Compra', urlTemplate: 'shopping/purchaseRequisitions', icono: 'fas fa-shopping-cart' },
  //     { texto: 'Compra por Factura', urlTemplate: 'shopping/purchaseInvoice', icono: 'fas fa-file-invoice-dollar' },
  //     { texto: 'Ingreso directo al inventario', urlTemplate: 'shopping/inventoryIncome', icono: 'fas fa-truck-loading' },
  //     { texto: 'Ordenes Compra', urlTemplate: 'shopping/purchaseOrder', icono: 'fas fa-store-alt' },
  //   ]
  // },
  // { texto: 'Administracion' },
  // {
  //   texto: 'Seguridad', hijos: [
  //     { texto: 'Menus', urlTemplate: 'seguridad/menus', icono: 'fas fa-bars' },
  //     { texto: 'Usuarios', urlTemplate: 'seguridad/usuarios', icono: 'fas fa-users' },
  //     { texto: 'Perfiles', urlTemplate: 'seguridad/perfiles', icono: 'far fa-id-badge' }
  //   ]
  // }];

  private Menus$ = new BehaviorSubject<menuInterface[]>([]);
  public responseMenus$: Observable<menuInterface[]> = this.Menus$.asObservable();

  constructor() { }

  consumirMenus() {
    let menus: any[] = [];
    menus = JSON.parse(localStorage.getItem('menus'));
    this.Menus$.next(menus);
  }

  setearMenusFiltrado(item) {
    this.Menus$.next(item);
    localStorage.setItem("menus", JSON.stringify(item));
  }
}
