import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ModalPuntoVentaService } from 'src/app/services/utils/modal-punto-venta-service.service';

@Component({
  selector: 'app-modal-punto-venta',
  templateUrl: './modal-punto-venta.component.html',
  styleUrls: ['./modal-punto-venta.component.css']
})
export class ModalPuntoVentaComponent implements OnInit {

  constructor(public puntoVentaService: ModalPuntoVentaService, public dialog: MatDialog) { 
    this.puntoVentaService.mostrarPuntosVenta('');
  }

  ngOnInit(): void {
  }

  seleccionarPuntoVenta(item) {
    this.puntoVentaService.setearValorSeleccionado(1,item);
    localStorage.setItem('puntoVenta', JSON.stringify(item));
    this.dialog.closeAll();
  }

}
