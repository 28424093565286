import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppNavbarComponent } from './app-navbar/app-navbar.component';
import { SpinnerComponent } from './spinner.component';
import { LoadingComponent } from './loading/loading.component';
import { ActionsButtonComponent } from './actions-button/actions-button.component';

//Angular Material Importaciones
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { LoadingToastComponent } from './loading-toast/loading-toast.component';
import { ModalPuntoVentaComponent } from './modal-punto-venta/modal-punto-venta.component';
import { PaginatorComponent } from './paginator/paginator.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { ScrollTopComponent } from './scroll-top/scroll-top.component';

@NgModule({
  declarations: [AppNavbarComponent, SpinnerComponent, LoadingComponent, ActionsButtonComponent, LoadingToastComponent, ModalPuntoVentaComponent, PaginatorComponent, ScrollTopComponent],
  imports: [
    CommonModule,
    MatProgressSpinnerModule,
    MatCardModule,
    MatButtonModule,
    MatIconModule,
    MatFormFieldModule,
    MatToolbarModule
  ],
  exports: [
    SpinnerComponent,
    LoadingComponent,
    ActionsButtonComponent,
    PaginatorComponent,
    ScrollTopComponent
  ],
})
export class SharedModule { }
