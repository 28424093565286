<mat-toolbar class="paginator">
    <span class="example-spacer"></span>
    <span class="mat-paginator-page-size-label">Items por pagina: {{ dataPaginator.itemPagina}} </span>
    <span class="mat-paginator-page-size-label">
        {{dataPaginator.desde}} -
        <span *ngIf="dataPaginator.lenght === dataPaginator.itemPagina">{{dataPaginator.hasta}}</span> 
        <span *ngIf="dataPaginator.lenght < dataPaginator.itemPagina">{{dataPaginator.totalItem}}</span>
        de 
        {{dataPaginator.totalItem}}
    </span>
    <button mat-icon-button [disabled]="dataPaginator.pageIndex <= 1" (click)="next(2)">
        <mat-icon>keyboard_arrow_left</mat-icon>
    </button>
    <button mat-icon-button (click)="next(1)" [disabled]="dataPaginator.lenght < dataPaginator.itemPagina">
        <mat-icon>keyboard_arrow_right</mat-icon>
    </button>
</mat-toolbar>