import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-recuperacion-password',
  templateUrl: './recuperacion-password.component.html',
  styleUrls: ['./recuperacion-password.component.css']
})
export class RecuperacionPasswordComponent implements OnInit {

  public hide1 = true;
  public hide2 = true;
  public hide3 = true;
  
  public claveForm: FormGroup;

  constructor(public authService:AuthService) {
    this.claveForm = new FormGroup({
      repetirPass: new FormControl('', [Validators.required]),
      passNuevo: new FormControl('', [Validators.required]),
      usuario: new FormControl('')
    });
  }

  ngOnInit(): void {
  }

  cambiarPassword(){

  }

  navegar(){
    this.authService.CerrarSesion();
  }
}
