<mat-card>
    <div class="row">
        <div class=" col-md-1 col-sm-4 col-6" *ngFor="let item of itemButtom">
            <div class="text-center">
                <button mat-icon-button [ngStyle]="{'color': item.color}" class="text-center icon">
                    <mat-icon>{{item.icono}}</mat-icon>
                </button>
                <p class="textButton">{{item.titulo}}</p>
            </div>
        </div>
    </div>
</mat-card> 