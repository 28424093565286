<div id="login">


    <div class="page">
       
        <div class="containerP">
            <div class="left">
                <div class="login text-center">Bienvenido</div>
                <div class="eula">
                    <img src="assets/images/undraw_authentication_fsn5.svg" alt="" class="img-fluid">
                </div>
            </div>
            <div class="right">
                <div class="row form" style="font-size: 12px;">
                    <form class="matPersonalizado" [formGroup]="formLogin" (ngSubmit)="login()" style="width: 100% !important;">

                        <mat-form-field appearance="outline" class="col-md-12" tourAnchor="correo">
                            <mat-label>Número Cuenta</mat-label>
                            <input autocomplete="off" matInput minlength="5" required formControlName="usuario" style="color: white !important;">
                        </mat-form-field>
                        

                        <mat-form-field appearance="outline" style="margin-top: 5%;" class="col-md-12"> 
                            <mat-label>Contraseña</mat-label>
                            <input matInput [type]="hide ? 'password' : 'text'" placeholder="Contraseña" formControlName="clave" required autocomplete="off">
                            <button type="button" mat-icon-button matSuffix (click)="hide = !hide"
                                [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                                <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                            </button>
                        </mat-form-field>

                        <!-- <mat-form-field appearance="outline" class="col-md-12" tourAnchor="password" >
                            <mat-label>Password</mat-label>
                            <input autocomplete="off" matInput formControlName="clave" required>
                        </mat-form-field> -->
    
    
                        <div class="text-center mt-4 col-12">
                            <button style="width: 50%; background-color: #452272; color: white;" type="submit" class="text-center" mat-raised-button >Entrar</button>
                        </div>
                    </form>
                    

                </div>

            </div>
        </div>
    </div>

</div>