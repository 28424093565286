<!-- ============================================================== -->
<!-- Profile - style you can find in header.scss -->
<!-- ============================================================== -->
<button mat-raised-button [matMenuTriggerFor]="profile" class="m-r-5" style="background-color: white;">
  <b>{{dataUsuario?.Usuario}}</b>
  <img src="../../../../assets/images/users/16196015_10154888128487744_6901111466535510271_n.png" alt="user" width="20%"
    class="profile-pic" style="margin-left: 5px;">
</button>

<mat-menu #profile="matMenu" class="mymegamenu">
  <button mat-menu-item [routerLink]="['/perfil']">
    <mat-icon>manage_accounts</mat-icon> Configuración
  </button>
  <!-- <button mat-menu-item [routerLink]="['seguridad/personalizacion']">
    <mat-icon>format_paint</mat-icon> Personalizar
  </button> -->
  <!-- <button mat-menu-item [routerLink]="['cambioPassword/2']" style="background-color: rgb(236, 77, 77);">
    <mat-icon>vpn_key</mat-icon> Cambiar Contraseña
  </button> -->
  <button mat-menu-item (click)="authServices.CerrarSesion()" class="exit">
    <mat-icon>exit_to_app</mat-icon> Salir
  </button>
</mat-menu>