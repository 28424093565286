import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import * as sha1 from 'sha1';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  // encapsulation: ViewEncapsulation.None
})
export class LoginComponent implements OnInit {
  public hide = true;
  menu = [
    {
        "idMenu": 26022,
        "descripcion": "Afiliados",
        "texto": "Pagos",
        "idPadre": 0,
        "icono": "fas fa-users",
        "url": "",
        "visible": true,
        "activo": true,
        "subItems": true,
        "idModulo": 4002,
        "hijos": [
            {
                "idMenu": 26023,
                "idPadre": 26022,
                "texto": "Registrar un pago",
                "descripcion": "Visualizar el listado de los afiliados",
                "icono": "fas fa-id-card-alt",
                "url": "/template/afiliados/posteo-pagos",
                "visible": true,
                "activo": true,
                "subItems": true
            },
            {
                "idMenu": 27033,
                "idPadre": 26022,
                "texto": "Ver estado de cuentas",
                "descripcion": "solitudes de ingresar pagos",
                "icono": "fa fa-file",
                "url": "/affiliates/solicitudes",
                "visible": true,
                "activo": true,
                "subItems": true
            },
            {
              "idMenu": 27033,
              "idPadre": 26022,
              "texto": "Realizar pago",
              "descripcion": "solitudes de ingresar pagos",
              "icono": "fa fa-file",
              "url": "/affiliates/solicitudes",
              "visible": true,
              "activo": true,
              "subItems": true
          }
        ]
    },
    {
        "idMenu": 27031,
        "descripcion": "mantenimientos del modulo de afiliados",
        "texto": "Solicitudes",
        "idPadre": 0,
        "icono": "fa fa-check",
        "url": "",
        "visible": true,
        "activo": true,
        "subItems": true,
        "idModulo": 4002,
        "hijos": [
            {
                "idMenu": 27032,
                "idPadre": 27031,
                "texto": "Constancia",
                "descripcion": "mantenimiento de las clinicas",
                "icono": "fa fa-clinic-medical",
                "url": "affiliates/clinica",
                "visible": true,
                "activo": true,
                "subItems": true
            },
            {
                "idMenu": 27034,
                "idPadre": 27031,
                "texto": "Certificados",
                "descripcion": "mantenimiento de conceptos del pago afiliados",
                "icono": "fas fa-money-bill-wave",
                "url": "affiliates/conceptosPago",
                "visible": true,
                "activo": true,
                "subItems": true
            },
            {
                "idMenu": 27035,
                "idPadre": 27031,
                "texto": "Solvencia",
                "descripcion": "mantenimientos estados de afiliacion",
                "icono": "fas fa-poll",
                "url": "affiliates/estadoAfiliacion",
                "visible": true,
                "activo": true,
                "subItems": true
            },
        ]
    }
];

  public formLogin: FormGroup;
  
  constructor(private toastr: ToastrService, public authService:AuthService, public router:Router) {
     this.formLogin = new FormGroup({
      usuario: new FormControl('', [Validators.required]),
      clave: new FormControl('', [Validators.required])
    });
  }

  ngOnInit(): void {

    localStorage.setItem('menus', JSON.stringify(this.menu));
    
  }

  login() {
    if (this.formLogin.invalid) {
      this.formLogin.markAllAsTouched();
      return;
    }    
    this.authService.LoginPublic({
      clave: sha1(this.formLogin.value.clave),
      usuario: this.formLogin.value.usuario,
      idTipoUsuario: 2
    });
  }

  loguearse(){
    this.router.navigateByUrl('afiliados/mi-perfil');
  }

}
