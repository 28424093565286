<div class="video-app">

  <div class="wrapper">

    <div class="main-container">
      <div class="profile">
        <img
          src="https://images.unsplash.com/photo-1559543434-3e99643d333d?ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80"
          alt="" class="profile-cover">
        <div class="profile-contact-info">

          <div class="profile-contact">
            <button mat-mini-fab (click)="volver()">
              <mat-icon>home</mat-icon>
            </button>
          </div>

        </div>

        <div class="profile-menu">
          <div class="profile-avatar">
            <img class="profile-img"
              src="assets/images/users/16196015_10154888128487744_6901111466535510271_n.png"
              alt="">
            <div class="profile-name">{{dataUsuario.Usuario}}</div>
          </div>
          <div class="menu-items">
           <b>Configuración</b>
          </div>
          <div class="follow-buttons">
            <!-- <button class="follow">Data</button> -->
          </div>
        </div>
      </div>
      <div class="trends">

        <div class="videos text-center">

          <div class="video" (click)="cambioPass()">
            <div class="video-time"></div>
            <img src="assets/images/undraw_secure_files_re_6vdh.svg" class="img-fluid">
            <div class="footerCard">
              <div class="video-content">Cambio de Contraseña</div>
              <div class="view"></div>
            </div>
            
          </div>

          <!-- <div class="video" (click)="cambioPass()">
            <div class="video-time"></div>
            <img src="assets/images/undraw_financial_data_es63.svg" class="img-fluid">
            <div class="footerCard">
              <div class="video-content">Personalización Dashboard</div>
              <div class="view"></div>
            </div>
            
          </div>

          <div class="video" (click)="cambioPass()">
            <div class="video-time"></div>
            <img src="assets/images/undraw_financial_data_es63.svg" class="img-fluid">
            <div class="footerCard">
              <div class="video-content">Personalización Dashboard</div>
              <div class="view"></div>
            </div>
            
          </div>

          <div class="video" (click)="cambioPass()">
            <div class="video-time"></div>
            <img src="assets/images/undraw_financial_data_es63.svg" class="img-fluid">
            <div class="footerCard">
              <div class="video-content">Personalización Dashboard</div>
              <div class="view"></div>
            </div>
            
          </div>

          <div class="video" (click)="cambioPass()">
            <div class="video-time"></div>
            <img src="assets/images/undraw_financial_data_es63.svg" class="img-fluid">
            <div class="footerCard">
              <div class="video-content">Personalización Dashboard</div>
              <div class="view"></div>
            </div>
            
          </div>

        </div> -->


      </div>
    </div>
  </div>