import * as $ from 'jquery';
import { MediaMatcher } from '@angular/cdk/layout';
import { Router } from '@angular/router';
import {ChangeDetectorRef, Component,OnDestroy,AfterViewInit} from '@angular/core';
import { AppNavbarComponent } from '../shared/app-navbar/app-navbar.component';
import { SideMenuComponent } from './side-menu/side-menu.component';
import { environment } from 'src/environments/environment';
import { DatosGeneralesFacadeService } from '../services/utils/datos-generales-facade.service';
import { Subscription } from 'rxjs';
import { AuthService } from '../services/auth.service';

/** @title Responsive sidenav */
@Component({
  selector: 'app-full-layout',
  templateUrl: 'full.component.html',
  styleUrls: ['full.style.css']
})
export class FullComponent implements OnDestroy, AfterViewInit {
  public mobileQuery: MediaQueryList;
  public opened:boolean = true;
  public nombreEmpresa = "";
  public suscripcion:Subscription = new Subscription();
  
  private _mobileQueryListener: () => void;

  constructor(
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    public datosGenerales:DatosGeneralesFacadeService,
    private authService:AuthService
  ) {
    // this.elemento.ontoggle("toggled");
    this.mobileQuery = media.matchMedia('(min-width: 768px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);

    this.authService.validarSitio();

    let datosEmpresa = JSON.parse(localStorage.getItem("infoEmpresa") || null);
    
    if(datosEmpresa === null){
      this.obtenerNombreEmpresa();
    }else{
      this.nombreEmpresa = datosEmpresa?.nombreEmpresa || '';
    }


    this.opened = (this.mobileQuery.matches === true) ? true : false; 

  }
  
  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
    this.suscripcion.unsubscribe();
  }
  ngAfterViewInit() {
  }

  obtenerNombreEmpresa(){
    this.datosGenerales.mostrarDatosGeneralesEmpresa('');

    this.suscripcion.add(
      this.datosGenerales.responseDatosGeneralesEmpresa$.subscribe((result)=>{
        localStorage.setItem("infoEmpresa", JSON.stringify(result));
        this.nombreEmpresa = result.nombreEmpresa;
      })
    );
  }
}
